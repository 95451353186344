import { ChangeEvent, ComponentType } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import type { RootState } from '../../../../app/store';
import {
    createGetCurrentStructureAction,
    createPostAction,
    createPostActionWithType,
    postponeStructureRefresh
} from '../../../../common/actions/form';
import { ACTION_TYPE } from '../../../../common/constants/form';
import { form, session } from '../../../../common/reducers';
import { handlePostponedStructureCheck } from './handlePostponedStructureCheck';

type OwnProps = { id: string };

type StateProps = {
    conversationUuid: string;
    refreshId: ReturnType<typeof form.getRefreshId>;
    isEnabledBrowserValidation: boolean;
} & ReturnType<FormSelector>;

type DispatchProps = {
    onChangeAction: typeof createPostAction;
    createGetCurrentStructureAction: typeof createGetCurrentStructureAction;
    onChangeActionWithType: typeof createPostActionWithType;
    postponeStructureRefresh: typeof postponeStructureRefresh;
};

type FormSelector =
    | typeof form['getTextFieldData']
    | typeof form['getSelectFieldData']
    | typeof form['getCheckableFieldData'];

type Props = Omit<
    StateProps,
    'preFillLink' | 'conversationUuid' | 'xPath' | 'triggerFocus' | 'triggerBlur' | 'value'
> & {
    postAction: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
    postActionWithType: (value: string, type: keyof typeof ACTION_TYPE) => void;
    defaultValue: StateProps['value'];
    fieldValue: StateProps['value'];
};

export const connectInput = <T extends Record<string, any>>(
    elementFieldsSelector: FormSelector,
    component: ComponentType<T>
) => {
    const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
        ...elementFieldsSelector(state, id),
        conversationUuid: session.getConversationUuid(state),
        refreshId: form.getRefreshId(state),
        isEnabledBrowserValidation: form.hasBrowserValidation(state)
    });

    const mergeProps = (
        {
            preFillLink,
            conversationUuid,
            value,
            xPath,
            postponeGetStructureTime,
            triggerFocus,
            triggerBlur,
            ...stateProps
        }: StateProps,
        {
            onChangeAction,
            createGetCurrentStructureAction,
            onChangeActionWithType,
            postponeStructureRefresh
        }: DispatchProps
    ): Props => ({
        ...stateProps,
        postponeGetStructureTime,
        defaultValue: value,
        fieldValue: value,
        postAction: event => {
            handlePostponedStructureCheck(postponeGetStructureTime, createGetCurrentStructureAction, conversationUuid);
            if (stateProps.customProps && stateProps.customProps.useCustomAction) {
                return onChangeActionWithType(ACTION_TYPE.CLICK_WITH_JS, value, xPath, conversationUuid);
            }
            return onChangeAction(event, xPath, conversationUuid);
        },
        postActionWithType: (value, type) => {
            handlePostponedStructureCheck(postponeGetStructureTime, createGetCurrentStructureAction, conversationUuid);
            onChangeActionWithType(type, value, xPath, conversationUuid);
        },
        onBlur: () => {
            if (triggerBlur) {
                onChangeActionWithType(ACTION_TYPE.BLUR, xPath, xPath, conversationUuid);
                postponeStructureRefresh();
            }
        },
        onFocus: () => {
            if (triggerFocus) {
                onChangeActionWithType(ACTION_TYPE.FOCUS, xPath, xPath, conversationUuid);
                postponeStructureRefresh();
            }
        }
    });

    const connector = connect(
        mapStateToProps,
        {
            onChangeAction: createPostAction,
            onChangeActionWithType: createPostActionWithType,
            createGetCurrentStructureAction,
            postponeStructureRefresh
        },
        mergeProps
    );

    return connector(component as ComponentType<ConnectedProps<typeof connector>>);
};

/**
 * @see https://react-bootstrap.github.io/components/forms/#form-control-props
 */
export const COMPONENT_CLASS = {
    SELECT: 'select',
    TEXTAREA: 'textarea',
    INPUT: 'input'
};
