import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { ChangeEvent, SyntheticEvent } from 'react';
import { ThunkAction } from 'redux-thunk';

import { IMirageAsyncFileUploadWithDeleteWidget } from '@stepstone/ats-scrapers-core/types';
import { uniqueId } from '../../common/uniqueId';
import { getBase64 } from '../../utils';
import { Action, InferActionFromThunk } from '../action';
import { FORM_KEY } from '../constants';
import {
    ACTION_TYPE,
    APPLY_ACTION_URL,
    CLICK_AND_WAIT_FOR_CLOSE,
    CLICK_AND_WAIT_FOR_FRAME,
    DEFAULT_ANCHOR_X_PATH,
    FETCH_FORM_ACTION,
    FORM_SUBMIT_ACTION,
    GET_STRUCTURE_ACTION,
    INVALIDATE_FILE_UPLOAD_ACTION,
    INVALIDATE_SESSION_ACTION,
    LOGO_ERROR_ACTION,
    POSTPONE_STRUCTURE_REFRESH_ACTION,
    POST_ACTION_ACTION,
    POST_UPLOAD_ACTION,
    PRE_CLICKED_UPLOAD_WITH_VALIDATION_CHECK,
    SYNCHRONIZE_FORM_VALUES_ACTION
} from '../constants/form';
import { REDIRECT_REASON_KEY } from '../constants/log';
import { isFileTooLarge } from '../fileUploadUtils';
import { FormStructureResponse, getData, postData } from './index';

export type FormActions =
    | InferActionFromThunk<typeof fetchInitialData>
    | InferActionFromThunk<typeof createGetCurrentStructureAction>
    | PostponeStructureRefreshAction
    | InvalidateFileUploadAction
    | InvalidateSessionAction
    | InferActionFromThunk<typeof createSubmitAction>
    | InferActionFromThunk<typeof createPostAction>
    | InferActionFromThunk<typeof createFileUploadAction>;

export const fetchInitialData = () => getData(FETCH_FORM_ACTION, 'form/provide');

export const createGetCurrentStructureAction = () => getData(GET_STRUCTURE_ACTION, 'form/structure');

type AnchorActionType = typeof ACTION_TYPE.CLICK | typeof ACTION_TYPE.ANCHOR_CLICK;

export const sendAnchorClickEvent = (inputXPath: string, conversationUuid: string, actionType: AnchorActionType) =>
    postData(POST_ACTION_ACTION, APPLY_ACTION_URL, {
        conversationUuid,
        actionType: actionType || ACTION_TYPE.ANCHOR_CLICK,
        inputXPath: inputXPath || DEFAULT_ANCHOR_X_PATH,
        data: inputXPath
    });

const mapEventTypeToAction = (action: string, target: HTMLInputElement | HTMLButtonElement | HTMLSelectElement) =>
    ({
        INPUT: ACTION_TYPE.TEXT_INPUT,
        CHANGE:
            (target.type === 'radio' && ACTION_TYPE.CLICK) ||
            (target.type && target.type.startsWith('select') && ACTION_TYPE.SELECT) ||
            ACTION_TYPE.CHANGE
    }[action] || action);

const getSelectedIndexes = (selected: number[], option: HTMLOptionElement, index: number) =>
    option.selected ? [...selected, index] : selected;

const getDataFromMultiple = (target: HTMLSelectElement) =>
    Array.from(target?.options).reduce(getSelectedIndexes, []).join(',');

const getSelectedValue = (target: HTMLSelectElement) =>
    target.hasAttribute('multiple') ? getDataFromMultiple(target) : target.selectedIndex;

export const createPostAction =
    (
        // ChangeEvent might not be right in every case
        { nativeEvent, target }: ChangeEvent<HTMLInputElement | HTMLButtonElement | HTMLSelectElement>,
        inputXPath: string,
        conversationUuid: string
    ): ThunkAction<
        Promise<FormStructureResponse> | void,
        any,
        void,
        Action<PostponeStructureRefreshAction['type']> | InferActionFromThunk<typeof createPostActionWithType>
    > =>
    dispatch => {
        const actionType = mapEventTypeToAction((nativeEvent.type || '').toUpperCase(), target);
        // Find use cases and you will notice that its not being used in these cases:
        const data =
            actionType === ACTION_TYPE.SELECT
                ? getSelectedValue(target as HTMLSelectElement)
                : target.type === 'checkbox'
                ? (target as HTMLInputElement).checked
                : target.value;
        if (actionType && target.tagName) {
            if (actionType === ACTION_TYPE.CLICK) {
                dispatch(postponeStructureRefresh());
            }
            return dispatch(createPostActionWithType(actionType, data, inputXPath, conversationUuid));
        }
    };

// actionType is not only actionType but also: CLICK_AND_WAIT_FOR_FRAME and CLICK_AND_WAIT_FOR_CLOSE and possibly much more
export const createPostActionWithType = (
    actionType: string,
    data: unknown,
    inputXPath: string,
    conversationUuid: string
) =>
    postData(POST_ACTION_ACTION, APPLY_ACTION_URL, {
        conversationUuid,
        actionType,
        inputXPath,
        data,
        timestamp: Date.now()
    });

export const createKeepAliveAction = (conversationUuid: string) =>
    postData(POST_ACTION_ACTION, APPLY_ACTION_URL, {
        conversationUuid,
        actionType: ACTION_TYPE.KEEP_ALIVE,
        inputXPath: '//',
        data: ''
    });

export type PostponeStructureRefreshAction = Action<typeof POSTPONE_STRUCTURE_REFRESH_ACTION, { id: number }>;

const createPostponeRefreshStructureAction = (id: number): PostponeStructureRefreshAction => ({
    id,
    type: POSTPONE_STRUCTURE_REFRESH_ACTION
});

export type SynchronizeFormValuesAction = ReturnType<typeof createSynchronizeFormValuesAction>;

export const createSynchronizeFormValuesAction = (): Action<typeof SYNCHRONIZE_FORM_VALUES_ACTION, { id: number }> => ({
    id: uniqueId(),
    type: SYNCHRONIZE_FORM_VALUES_ACTION
});

export const postponeStructureRefresh =
    (timeout = 700): ThunkAction<void, any, void, Action<PostponeStructureRefreshAction['type']>> =>
    (dispatch, getState) => {
        const id = uniqueId();

        dispatch(createPostponeRefreshStructureAction(id));

        setTimeout(
            () => getState()[FORM_KEY].refreshStructureId === id && dispatch(createGetCurrentStructureAction()),
            timeout
        );
    };

export const createDeleteFileAction = (event: SyntheticEvent, inputXPath: string, conversationUuid: string) =>
    postData(POST_ACTION_ACTION, APPLY_ACTION_URL, {
        conversationUuid,
        inputXPath,
        actionType: ACTION_TYPE.CLEAR
    });

type InvalidateFileUploadAction = Action<typeof INVALIDATE_FILE_UPLOAD_ACTION, { id: string; fileSize: number }>;

export const setFileValidation = (id: string, fileSize: number): InvalidateFileUploadAction => ({
    type: INVALIDATE_FILE_UPLOAD_ACTION,
    id,
    fileSize
});

export const fileUploadErrorHandler = (error: Error): void => {
    if (!/File not found|File is too large/.test(`${error}`)) {
        throw error;
    }
};
type UploadOptions = {
    fileSizeLimit?: number | undefined;
    uploadMethod?: NonNullable<IMirageAsyncFileUploadWithDeleteWidget['uploadMethod']>;
};

export const createFileUploadAction =
    (
        files: FileList | null,
        inputXPath: string,
        conversationUuid: string,
        id: string,
        uploadOptions?: UploadOptions | undefined
    ): ThunkAction<Promise<FormStructureResponse | void>, any, void, InvalidateFileUploadAction | FileUploadAction> =>
    dispatch => {
        const file = files?.[0];
        if (!file) {
            return Promise.reject(new Error('File not found'));
        }

        if (isFileTooLarge(file, uploadOptions?.fileSizeLimit)) {
            dispatch(setFileValidation(id, file.size));
            return Promise.reject(new Error('File is too large'));
        }

        return getBase64(file).then(content => {
            return dispatch(
                createAsyncFileUploadAction(file.name, content.value, inputXPath, conversationUuid, uploadOptions)
            );
        });
    };

export const createPreClickUploadWithValidationCheck =
    (
        files: FileList | null,
        uploadType: typeof PRE_CLICKED_UPLOAD_WITH_VALIDATION_CHECK,
        buttonSelector: string,
        inputXPath: string,
        conversationUuid: string,
        id: string,
        ...otherProps: any[] // TO BE REMOVED?
    ): ThunkAction<Promise<FormStructureResponse | void>, any, void, InvalidateFileUploadAction | FileUploadAction> =>
    dispatch => {
        const file = files?.[0];
        if (!file) {
            return Promise.reject(new Error('File not found'));
        }

        if (isFileTooLarge(file)) {
            dispatch(setFileValidation(id, file.size));
            return Promise.reject(new Error('File is too large'));
        }

        return getBase64(file).then(content => {
            return dispatch(
                postData(POST_UPLOAD_ACTION, 'apply/upload', {
                    cv: {
                        name: file.name,
                        contentBase64: content.value
                    },
                    conversationUuid: conversationUuid,
                    uploadType,
                    buttonSelector,
                    inputXPath,
                    ...otherProps
                })
            );
        });
    };

type FileUploadAction = InferActionFromThunk<typeof createAsyncFileUploadAction>;

const createAsyncFileUploadAction = (
    fileName: string,
    fileContent: string,
    inputXPath: string,
    conversationUuid: string,
    uploadOptions?: UploadOptions
) => {
    const { uploadMethod, ...uploadOptionsRest } = uploadOptions || {};
    return postData(POST_UPLOAD_ACTION, 'apply/upload', {
        cv: {
            name: fileName,
            contentBase64: fileContent
        },
        conversationUuid,
        inputXPath,
        // we need to send each component of the uploadMethod separately
        ...uploadMethod,
        ...uploadOptionsRest
    });
};

export const createSubmitAction = (
    event: SyntheticEvent,
    inputXPath: string,
    conversationUuid: string,
    buttonRole: string /* Because we do uppercase on ButtonRole values */,
    timeout?: number
) =>
    postData(FORM_SUBMIT_ACTION, `apply/submit`, {
        inputXPath,
        conversationUuid,
        buttonRole,
        timeout
    });

export type LogoErrorAction = Action<typeof LOGO_ERROR_ACTION>;

export const createLogoErrorAction = (): LogoErrorAction => ({ type: LOGO_ERROR_ACTION });

type InvalidateSessionAction = Action<typeof INVALIDATE_SESSION_ACTION, { [REDIRECT_REASON_KEY]: string }>;

export const invalidateSessionAction = (reason: string): InvalidateSessionAction => ({
    type: INVALIDATE_SESSION_ACTION,
    [REDIRECT_REASON_KEY]: reason
});

export const throttledAction = <T extends (...args: any) => any>(action: T) =>
    throttle(action, 300, { leading: false, trailing: true });

export const debouncedAction = <T extends (...args: any) => any>(action: T) => debounce(action, 500, { maxWait: 1500 });

export const createClickAndWaitForFrameAction = (inputXPath: string, conversationUuid: string, data: string) =>
    createPostActionWithType(CLICK_AND_WAIT_FOR_FRAME, data, inputXPath, conversationUuid);

export const clickAndCloseAction = (inputXPath: string, conversationUuid: string, data: string) =>
    createPostActionWithType(CLICK_AND_WAIT_FOR_CLOSE, data, inputXPath, conversationUuid);

export const clickAndWaitForElement = (
    actionType: string,
    data: string,
    inputXPath: string,
    conversationUuid: string
) => {
    return createPostActionWithType(actionType, data, inputXPath, conversationUuid);
};
