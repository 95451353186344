import React, { FC } from 'react';

import { SurveyType } from '../../constants/form';

const DEFAULT_COMPANY_NAME = 'the recruiting company';

const companyNameOrDefault = (companyName: string | null) => companyName || DEFAULT_COMPANY_NAME;

interface IProps {
    surveyType: string;
    companyName: string | null;
}

const FormHeader: FC<IProps> = ({ surveyType, companyName }) => {
    switch (surveyType) {
        case SurveyType.REGISTRATION:
            return (
                <div className="row">
                    <div className="col-xs-12 input-row">
                        <h4>Register an Account with {companyNameOrDefault(companyName)}</h4>
                    </div>
                </div>
            );
        case SurveyType.APPLICATION_FOR_A_JOB:
        default:
            return null;
    }
};

export default FormHeader;
