export const VisibilityState = {
    HIDDEN: 'hidden',
    VISIBLE: 'visible'
} as const;

export function getVisibilityState(): VisibilityState {
    if (typeof document.visibilityState !== 'undefined') {
        return document.visibilityState;
    }
    if (typeof document.mozVisibilityState !== 'undefined') {
        return document.mozVisibilityState;
    }
    if (typeof document.webkitVisibilityState !== 'undefined') {
        return document.webkitVisibilityState;
    }
    return VisibilityState.VISIBLE;
}
