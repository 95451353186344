import { connect } from 'react-redux';
import {
    createGetCurrentStructureAction,
    createPostActionWithType,
    postponeStructureRefresh
} from '../../../../../common/actions/form';
import { ACTION_TYPE } from '../../../../../common/constants/form';
import { form, session } from '../../../../../common/reducers';

import {
    CancelationSignal,
    findElementByLocator,
    handleReadOptionsFromBackend,
    LocatorsRef,
    SelectOptionItem,
    toSelectOption,
    WidgetWithItems
} from './AsyncSelectAutosuggestWidgetUtils';
import {
    AsyncSelectAutosuggestWidgetView,
    DispatchProps,
    OwnProps,
    Props,
    StateProps
} from './AsyncSelectAutosuggestWidgetView';
import { RootState } from '../../../../../app/store';

// Time in milliseconds, the timer should delay in between asking server for structure
const REPETITIVE_CALL_DELAY = 200;

const mapStateToProps = (state: RootState, { id }: OwnProps): StateProps => ({
    ...form.getElement(state, id),
    id,
    conversationUuid: session.getConversationUuid(state)
});

const mapDispatchToProps = { createPostActionWithType, createGetCurrentStructureAction, postponeStructureRefresh };

const mergeProps = (
    {
        conversationUuid,
        selectedItems,
        repeatInterval = REPETITIVE_CALL_DELAY,
        isMulti = false,
        isClearable = false,
        isSearchable = false,
        ...stateProps
    }: StateProps,
    { createPostActionWithType, createGetCurrentStructureAction, postponeStructureRefresh }: DispatchProps
): Props => {
    //it calculates value for selected item / items
    const defaultValue = (selectedItems && selectedItems.map(toSelectOption)) || [];

    const useCustomClickAction = !!(stateProps.customProps && stateProps.customProps.useCustomAction);

    const refreshForm = (locators: LocatorsRef): Promise<WidgetWithItems | { items: never[] }> =>
        createGetCurrentStructureAction().then(result => {
            return (
                (result && result.elements && findElementByLocator(result.elements, locators)) || {
                    items: []
                }
            );
        });

    // it sends value of user
    const sendInputValueToBackend = (locators: LocatorsRef, value: string) =>
        isSearchable
            ? createPostActionWithType(
                  ACTION_TYPE.TEXT_INPUT,
                  value,
                  // @ts-ignore xPath is always present
                  locators.current?.fieldXPath || locators.current?.xPath,
                  conversationUuid
              )
            : undefined;

    //it loads options based on value
    const loadOptions = (
        locators: LocatorsRef,
        cancelationSignal: CancelationSignal['cancelationSignal'],
        inputValue?: string
    ) => {
        if (inputValue) {
            sendInputValueToBackend(locators, inputValue);
            return handleReadOptionsFromBackend({ repeatInterval, cancelationSignal }, () => refreshForm(locators));
        }
        return handleReadOptionsFromBackend({ repeatInterval, cancelationSignal }, () => refreshForm(locators));
    };

    const createClickAction = (xPath: string, useCustomAction: boolean) => {
        if (useCustomAction) {
            return createPostActionWithType(ACTION_TYPE.CLICK_WITH_JS, xPath, xPath, conversationUuid);
        }
        return createPostActionWithType(ACTION_TYPE.CLICK, xPath, xPath, conversationUuid);
    };

    //function to select option to be selected /removed / cleared
    const selectSuggestion = ({ xPath }: SelectOptionItem) =>
        createClickAction(xPath, useCustomClickAction).then(() => postponeStructureRefresh());

    //function to find element to be removed
    const removeValue = (valueToBeRemoved: SelectOptionItem) => {
        const removedOption = defaultValue.find(option => option.value === valueToBeRemoved.value) || valueToBeRemoved;
        return selectSuggestion(removedOption);
    };

    const selectOptionBasedIfIsMulti = (option: SelectOptionItem | undefined, value: any) =>
        isMulti && option != null ? selectSuggestion(option) : selectSuggestion(value);

    return {
        ...stateProps,

        // function to load options on click
        onFocus: (locators: LocatorsRef, cancelationSignal: CancelationSignal['cancelationSignal']) => {
            if (locators.current?.clickXPath) {
                // Try to read options because dropdown might be already open
                return handleReadOptionsFromBackend({ repeatInterval, maxRetries: 0, cancelationSignal }, () =>
                    refreshForm(locators)
                ).then(elements => {
                    // When there is no options proceed with loading
                    if (elements.length === 0 && locators.current?.clickXPath) {
                        return createClickAction(locators.current?.clickXPath, useCustomClickAction).then(() =>
                            loadOptions(locators, cancelationSignal)
                        );
                    }

                    return elements;
                });
            }
            return Promise.resolve([]);
        },

        loadOptions,

        //function to click outside component to close menu of options
        onBlur: (locators: LocatorsRef) =>
            // Blur shouldn't use clickWitJS because it will not trigger proper events on the DOM tree
            locators.current?.blurXPath ? createClickAction(locators.current.blurXPath, false) : undefined,

        scrollAndGetData: (locators: LocatorsRef, cancelationSignal: CancelationSignal['cancelationSignal']) => {
            if (locators.current?.scrollXPath) {
                createPostActionWithType(
                    ACTION_TYPE.SCROLL_TO_THE_BOTTOM_OF_ELEMENT,
                    locators.current?.scrollXPath,
                    locators.current?.scrollXPath,
                    conversationUuid
                );
                return loadOptions(locators, cancelationSignal);
            }
            return Promise.resolve([]);
        },

        // function to execute action based on type of chosen action
        // -> 'select-option' -> (single or multi select) choose option,
        // -> 'remove-value' -> (multi select) delete value one by one with 'the remove button'
        // -> 'pop-value' -> (single select if 'isClearable' is set to true or multi select) delete value one by one using 'backspace'
        // -> 'clear' -> (single or multi select if any has 'isClearable' set to true) delete all values with 'the clear button'
        selectOption: (values, { action, option, removedValue }, locators: LocatorsRef) =>
            ({
                'create-option': () => undefined,
                'deselect-option': () => undefined,
                'pop-value': () => (removedValue ? removeValue(removedValue) : undefined),
                'remove-value': () => (removedValue ? removeValue(removedValue) : undefined),
                'select-option': () => selectOptionBasedIfIsMulti(option, values),
                'set-value': () => undefined,
                clear: () => sendInputValueToBackend(locators, '')
            }[action]()),

        defaultValue,
        isMulti,
        isClearable,
        isSearchable
    };
};

// @ts-expect-error 2769 concerning `mapDispatchToProps` overloads (ThunkAction)
export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(AsyncSelectAutosuggestWidgetView);
