import type { RootState } from '../../app/store';
import * as formSelectors from '../../mirage/reducers/form';
import * as constants from '../constants';
import * as guiSelectors from './gui';
import * as languageSelectors from './language';
import * as sessionSelectors from './session';

const SELECTOR_PREFIXES = ['is', 'get', 'has'];
const isValidSelector = (name: string, selector: any) =>
    typeof selector === 'function' && SELECTOR_PREFIXES.some(prefix => name.startsWith(prefix));

type PrefixedSelector = `${'is' | 'get' | 'has'}${string}`;

type SelectorWithPrefix<Key> = Key extends PrefixedSelector ? Key : never;

export const prepareSelectors = <SliceName extends keyof RootState, SelectorMap extends Record<string, any>>(
    key: SliceName,
    selectors: SelectorMap
) =>
    Object.entries(selectors)
        .filter(([name, selector]) => isValidSelector(name, selector))
        .reduce(
            (obj, [name, selector]) => ({
                ...obj,
                [name]: (state: RootState, ...args: any[]) => selector(state[key], ...args)
            }),
            {} as {
                [SelectorKey in SelectorWithPrefix<keyof SelectorMap>]: SelectorMap[SelectorKey] extends <
                    T extends Record<string, any>
                >(
                    state: RootState[SliceName],
                    ...args: infer TailArgs
                ) => T
                    ? <Returns extends ReturnType<SelectorMap[SelectorKey]>>(
                          state: RootState,
                          ...args: TailArgs
                      ) => Returns
                    : SelectorMap[SelectorKey] extends (
                          state: RootState[SliceName],
                          ...args: infer TailArgs
                      ) => infer Returns
                    ? (state: RootState, ...args: TailArgs) => Returns
                    : never;
            }
        );

export const gui = prepareSelectors(constants.GUI_KEY, guiSelectors);

export const session = prepareSelectors(constants.SESSION_KEY, sessionSelectors);

export const language = prepareSelectors(constants.LANGUAGE_KEY, languageSelectors);

export const form = prepareSelectors(constants.FORM_KEY, formSelectors);
